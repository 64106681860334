module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aeta Ako Pilipino Ako Inc","short_name":"aapa","start_url":"/","lang":"en","background_color":"#FFCE7E","theme_color":"#22577A","display":"minimal-ui","icon":"src/assets/images/logo.png","localize":[{"start_url":"/ph/","lang":"ph"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e067b216e03c9d5a5b37609590da48db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
