// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/BlogArticle.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-involved-js": () => import("./../../../src/templates/Involved.js" /* webpackChunkName: "component---src-templates-involved-js" */),
  "component---src-templates-news-and-updates-js": () => import("./../../../src/templates/NewsAndUpdates.js" /* webpackChunkName: "component---src-templates-news-and-updates-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

